import { Trans } from "@lingui/macro";

import styled from "styled-components/macro";
import { ThemedText } from "theme";

import ChartIcon from "assets/images/homeData/chart.png"
import CalculatorIcon from "assets/images/homeData/calculator.png"
import keyIcon from "assets/images/homeData/key.png"
import { isMobile } from "utils/userAgent";
import { AutoColumn } from "components/Column";
import { BorderCard, DarkBlueDataCard } from "components/Card";

const Container = styled.div`
  width: 1200px;
  display: flex;
  flex-direction: column;
  gap: 4rem;
  margin-top: 15rem;
  ${({ theme }) => theme.mediaWidth.upToMedium`
    margin-top: 8rem;
    width: 100%;
  `};

  ${({ theme }) => theme.mediaWidth.upToSmall`
    margin-top: 8rem;
    width: 100%;
  `};
`;

const ResponsiveCards = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  gap: 2rem;
  ${({ theme }) => theme.mediaWidth.upToMedium`
    flex-direction: column;
  `};

  ${({ theme }) => theme.mediaWidth.upToSmall`
    margin-top: column;
  `};
`;

const Column = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 2rem;
    padding: 20px;
`;

const StyledCard = styled(DarkBlueDataCard)`
    padding: 0;
    width: 370px;
    height: 462px;
    ${({ theme }) => theme.mediaWidth.upToMedium`
        width: 100%;
        height: 419px;
    `};

    ${({ theme }) => theme.mediaWidth.upToSmall`
        width: 100%;
        height: 419px;
    `};
`;



export function AppData() {
    return (
        <Container>
            <AutoColumn gap="2rem">
                <ThemedText.TitleSection textAlign="center">
                    <Trans>OUR</Trans> <b style={{ color: '#5DFFFF' }}>APP</b>
                </ThemedText.TitleSection>
                {isMobile ?
                    <AutoColumn gap="1rem">
                        <ThemedText.MediumText textAlign="center">
                            <Trans>Green Block Capital offers users an interactive and transparent experience through its app, which connects directly to their wallets.</Trans>
                        </ThemedText.MediumText>
                        <ThemedText.MediumText textAlign="center">
                            <Trans>This key functionality allows users to not only monitor the status of their mining and returns but also to gain a complete view of the overall system.</Trans>
                        </ThemedText.MediumText>
                    </AutoColumn>
                    :
                    <ThemedText.MediumText textAlign="center">
                        <Trans>Green Block Capital offers users an interactive and transparent experience through its app, which connects directly to their wallets. This key functionality allows users to not only monitor the status of their mining and returns but also to gain a complete view of the overall system.</Trans>
                    </ThemedText.MediumText>}
            </AutoColumn>
            <ResponsiveCards>
                <StyledCard>
                    <BorderCard>
                        <Column>
                            <img src={keyIcon} alt="key-icon" width={100} height={100} />
                            <ThemedText.LargeText color="primaryData1" textAlign="center">
                                <Trans>User Access and Control</Trans>
                            </ThemedText.LargeText>
                            <ThemedText.MediumText textAlign="center">
                                <Trans>An interactive app connecting directly to your wallet for real-time monitoring.</Trans>
                            </ThemedText.MediumText>
                        </Column>
                    </BorderCard>
                </StyledCard>
                <StyledCard>
                    <BorderCard>
                        <Column>
                            <img src={CalculatorIcon} alt="calculator-icon" width={100} height={100} />
                            <ThemedText.LargeText color="primaryData1" textAlign="center">
                                <Trans>Mining Status and Returns Visualization</Trans>
                            </ThemedText.LargeText>
                            <ThemedText.MediumText textAlign="center">
                                <Trans>Stay informed about your investment performance.</Trans>
                            </ThemedText.MediumText>
                        </Column>
                    </BorderCard>
                </StyledCard>
                <StyledCard>
                    <BorderCard>
                        <Column>
                            <img src={ChartIcon} alt="chart-icon" width={100} height={100} />
                            <ThemedText.LargeText color="primaryData1" textAlign="center">
                                <Trans>Staking in Green Block</Trans>
                            </ThemedText.LargeText>
                            <ThemedText.MediumText textAlign="center">
                                <Trans>Participate in our staking programs through the app for added benefits.</Trans>
                            </ThemedText.MediumText>
                        </Column>
                    </BorderCard>
                </StyledCard>
            </ResponsiveCards>
        </Container>
    );
}
