import { Trans } from "@lingui/macro";

import styled from "styled-components/macro";
import { ThemedText } from "theme";

import AgilityIcon from "assets/images/homeData/agility.svg"
import IntegrityIcon from "assets/images/homeData/integrity.svg"
import InnovationIcon from "assets/images/homeData/innovation.svg"
import { AutoColumn } from "components/Column";
import { BorderLargeCard, DarkBlueDataCard } from "components/Card";

const Container = styled.div`
  width: 1200px;
  display: flex;
  flex-direction: column;
  gap: 4rem;
  margin-top: 15rem;
  ${({ theme }) => theme.mediaWidth.upToMedium`
    margin-top: 8rem;
    width: 100%;
  `};

  ${({ theme }) => theme.mediaWidth.upToSmall`
    margin-top: 8rem;
    width: 100%;
  `};
`;

const ResponsiveCards = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  gap: 2rem;
  ${({ theme }) => theme.mediaWidth.upToMedium`
    flex-direction: column;
  `};

  ${({ theme }) => theme.mediaWidth.upToSmall`
    margin-top: column;
  `};
`;

const Column = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 2rem;
    padding: 20px;
`;

const StyledCard = styled(DarkBlueDataCard)`
    padding: 0;
    width: 370px;
    height: 700px;
    ${({ theme }) => theme.mediaWidth.upToMedium`
        width: 100%;
        height: 600px;
    `};

    ${({ theme }) => theme.mediaWidth.upToSmall`
        width: 100%;
        height: 600px;
    `};
`;



export function Values() {
    return (
        <Container id={"valuesData"}>
            <AutoColumn gap="2rem">
                <ThemedText.TitleSection textAlign="center">
                    <Trans>Values</Trans>
                </ThemedText.TitleSection>
            </AutoColumn>
            <ResponsiveCards>
                <StyledCard>
                    <BorderLargeCard>
                        <Column>
                            <img src={AgilityIcon} alt="agility-icon" width={100} height={100} />
                            <ThemedText.LargeText color="primaryData1" textAlign="center">
                                <Trans>Agility</Trans>
                            </ThemedText.LargeText>
                            <ThemedText.MediumText>
                                <Trans>Green Block Capital boasts a global and agile team essential for the launch and management of the $DATA token. Our adaptability enables quick responses to operational changes, ensuring innovative solutions and keeping the $DATA project at the forefront of a dynamic market.</Trans>
                            </ThemedText.MediumText>
                        </Column>
                    </BorderLargeCard>
                </StyledCard>
                <StyledCard>
                    <BorderLargeCard>
                        <Column>
                            <img src={IntegrityIcon} alt="integrity-icon" width={100} height={100} />
                            <ThemedText.LargeText color="primaryData1" textAlign="center">
                                <Trans>Integrity</Trans>
                            </ThemedText.LargeText>
                            <ThemedText.MediumText>
                                <Trans>Integrity is paramount in $DATA token management. We prioritize transparency and a holistic approach across all operations, using technology to ensure fair and equitable decisions when collaborating with investors, stakeholders, and community members.</Trans>
                            </ThemedText.MediumText>
                        </Column>
                    </BorderLargeCard>
                </StyledCard>
                <StyledCard>
                    <BorderLargeCard>
                        <Column>
                            <img src={InnovationIcon} alt="innovation-icon" width={100} height={100} />
                            <ThemedText.LargeText color="primaryData1" textAlign="center">
                                <Trans>Innovation</Trans>
                            </ThemedText.LargeText>
                            <ThemedText.MediumText>
                                <Trans>We're dedicated to innovating with the $DATA project, revolutionizing engineering, software development, and sustainable business models. The $DATA token embodies this commitment, providing accessible investment in DATA Centers and innovative tools for value creation among stakeholders.</Trans>
                            </ThemedText.MediumText>
                        </Column>
                    </BorderLargeCard>
                </StyledCard>
            </ResponsiveCards>
        </Container>
    );
}
