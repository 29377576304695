import { Trans } from "@lingui/macro";
import styled from "styled-components/macro";
import { ThemedText } from "theme";

import AboutImg from "assets/images/homeData/about-us.svg"
import { isMobile } from "utils/userAgent";

const Container = styled.div`
  width: 1200px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-top: 15rem;
  gap: 10rem;
  ${({ theme }) => theme.mediaWidth.upToMedium`
    width: 100%;
    flex-direction: column;
    gap: 2rem;
    margin-top: 8rem;
  `};

  ${({ theme }) => theme.mediaWidth.upToSmall`
    width: 100%;
    flex-direction: column;
    gap: 2rem;
    margin-top: 8rem;
  `};
`;

const Image = styled.img`
  width: 434px;
  height: 426.37px;
  ${({ theme }) => theme.mediaWidth.upToMedium`
    width: 269px;
    height: 264.27px;
  `};

  ${({ theme }) => theme.mediaWidth.upToSmall`
    width: 269px;
    height: 264.27px;
  `};
`;

const Column = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 2rem;
    ${({ theme }) => theme.mediaWidth.upToMedium`
      align-items: center;
    `};

    ${({ theme }) => theme.mediaWidth.upToSmall`
      align-items: center;
    `};
`;

export function AboutUs() {
  return (
    <Container id={"aboutData"}>
      {!isMobile && <Image src={AboutImg} alt="about-icon" />}
      <Column>
        <ThemedText.TitleSection textAlign={isMobile ? "center" : "left"}>
          <Trans>ABOUT US</Trans>
        </ThemedText.TitleSection>
        {isMobile && <Image src={AboutImg} alt="about-icon" />}
        <ThemedText.MediumText>
          <Trans>Discover the heartbeat of the crypto mining industry with Green Block Capital (GBT). Headquartered in Abu Dhabi, UAE, we specialize in cryptocurrency mining and hosting services.</Trans>
        </ThemedText.MediumText>
        <ThemedText.MediumText>
          <Trans>With 2 MW of power in our facilities and key collaborations with industry leaders like BITMAIN and Whatsminer, GBT is at the forefront of innovation and growth.</Trans>
        </ThemedText.MediumText>
      </Column>
    </Container>
  );
}
