import { Trans } from "@lingui/macro";
import styled from "styled-components/macro";
import { useState } from "react";
import Row from "components/Row";


const HeaderLinks = styled(Row)`
  justify-self: center;
  width: fit-content;
  padding: 2px;
  border-radius: 16px;
  display: grid;
  grid-auto-flow: column;
  grid-gap: 10px;
  overflow: auto;
  align-items: center;
  ${({ theme }) => theme.mediaWidth.upToLarge`
    justify-self: start;  
    `};
  ${({ theme }) => theme.mediaWidth.upToMedium`
    justify-self: center;
  `};
  ${({ theme }) => theme.mediaWidth.upToMedium`
    flex-direction: row;
    justify-content: space-between;
    justify-self: center;
    z-index: 99;
    position: fixed;
    bottom: 0; right: 50%;
    transform: translate(50%,-50%);
    margin: 0 auto;
    background-color: ${({ theme }) => theme.bg0};
    border: 1px solid ${({ theme }) => theme.bg3};
    box-shadow: 0px 6px 10px rgb(0 0 0 / 2%);
  `};
  ${({ theme }) => theme.mediaWidth.upToSmall`
    width: 70%;
  `};
`

const ButtonLink = styled.a<{ activeColor: boolean }>`
  color: ${({ theme, activeColor }) => activeColor ? theme.primaryData1 : theme.text1};
  text-decoration: none;
  :hover {
    color: ${({ theme }) => theme.primaryData1};
  }
`;
const StyledRow = styled(Row)`
  gap: 2rem;
`;

export default function MenuData() {
    const [active, setActive] = useState(0);
    const handleSetActive = (n: number) => {
        setActive(n);
    };


    return (
        <HeaderLinks>
            <StyledRow>
                <ButtonLink id={`home-nav-link`} href="/" onClick={() => {
                    handleSetActive(0);
                }} activeColor={active === 0}>
                    <Trans>Home</Trans>
                </ButtonLink>


                <ButtonLink
                    id={`about-nav-link`}
                    href="#aboutData"
                    onClick={() => {

                        handleSetActive(1);
                    }} activeColor={active === 1}>
                    <Trans>About</Trans>
                </ButtonLink>


                <ButtonLink
                    id={`objectives-nav-link`}
                    href="#objectivesData"
                    onClick={() => {

                        handleSetActive(2);
                    }} activeColor={active === 2}>
                    <Trans>Objectives</Trans>
                </ButtonLink>



                <ButtonLink
                    id={`features-nav-link`}
                    href="#features"
                    onClick={() => {

                        handleSetActive(3);
                    }} activeColor={active === 3}>
                    <Trans>Technical Features</Trans>
                </ButtonLink>


                <ButtonLink
                    id={`roadmap-nav-link`}
                    href="#roadmap"
                    onClick={() => {

                        handleSetActive(4);
                    }} activeColor={active === 4}>
                    <Trans>RoadMap</Trans>
                </ButtonLink>

                <ButtonLink
                    id={`values-nav-link`}
                    href="#valuesData"
                    onClick={() => {

                        handleSetActive(5);
                    }} activeColor={active === 5}>
                    <Trans>Values</Trans>
                </ButtonLink>



                <ButtonLink
                    id={`business-nav-link`}
                    href="#business"
                    onClick={() => {

                        handleSetActive(6);
                    }} activeColor={active === 6}>
                    <Trans>Business Plan</Trans>
                </ButtonLink>

              {/*   <ButtonLink
                    id={`gbc-nav-link`}
                    href="/"
                    activeColor={false}>
                    Green Block
                </ButtonLink> */}

            </StyledRow>
        </HeaderLinks>
    );
}
