import React, { useMemo } from "react";
import { Text, TextProps as TextPropsOriginal } from "rebass";
import styled, {
  createGlobalStyle,
  css,
  DefaultTheme,
  ThemeProvider as StyledComponentsThemeProvider,
} from "styled-components/macro";
import { isMobile } from "utils/userAgent";

import { useIsDarkMode } from "../state/user/hooks";
import { Colors } from "./styled";
import { useActiveLocale } from "hooks/useActiveLocale";

type TextProps = Omit<TextPropsOriginal, "css">;

export const MEDIA_WIDTHS = {
  upToExtraSmall: 500,
  upToSmall: 720,
  upToMedium: 960,
  upToLarge: 1280,
};

// Migrating to a standard z-index system https://getbootstrap.com/docs/5.0/layout/z-index/
// Please avoid using deprecated numbers
export enum Z_INDEX {
  deprecated_zero = 0,
  deprecated_content = 1,
  dropdown = 1000,
  sticky = 1020,
  fixed = 1030,
  modalBackdrop = 1040,
  offcanvas = 1050,
  modal = 1060,
  popover = 1070,
  tooltip = 1080,
}

const mediaWidthTemplates: {
  [width in keyof typeof MEDIA_WIDTHS]: typeof css;
} = Object.keys(MEDIA_WIDTHS).reduce((accumulator, size) => {
  (accumulator as any)[size] = (a: any, b: any, c: any) => css`
    @media (max-width: ${(MEDIA_WIDTHS as any)[size]}px) {
      ${css(a, b, c)}
    }
  `;
  return accumulator;
}, {}) as any;

const white = "#FFFFFF";
const black = "#000000";

function colors(darkMode: boolean): Colors {
  return {
    darkMode,
    // base
    white,
    black,

    // text
    text1: darkMode ? "#FFFFFF" : "#FFFFFF",
    text2: darkMode ? "#C3C5CB" : "#565A69",
    text3: darkMode ? "#8F96AC" : "#6E727D",
    text4: darkMode ? "#B2B9D2" : "#C3C5CB",
    text5: darkMode ? "#2C2F36" : "#EDEEF2",

    // backgrounds / greys
    bgPage: darkMode
      ? "radial-gradient(60.67% 61.22% at 54.53% 42.49%, #1A0351 0%, #07071C 84.73%)"
      : "radial-gradient(60.67% 61.22% at 54.53% 42.49%, #1A0351 0%, #07071C 84.73%)",
    bg0: darkMode ? "#030315" : "#030315",
    bg1: darkMode ? "#07051D" : "#07051D",
    bg2: darkMode ? "#030315" : "#030315",
    bg3: darkMode ? "#40444F" : "#CED0D9",
    bg4: darkMode ? "#565A69" : "#888D9B",
    bg5: darkMode ? "#6C7284" : "#888D9B",
    bg6: darkMode ? "#1A2028" : "#6C7284",

    // backgrounds cards
    bgCard0: darkMode ? "#191B1F66" : "#FFFFFF66",
    bgCard1: darkMode ? "#21242966" : "#F7F8FA66",
    bgCard2: darkMode ? "#2C2F3666" : "#EDEEF266",

    //specialty colors
    modalBG: darkMode ? "rgba(0,0,0,.425)" : "rgba(0,0,0,0.3)",
    advancedBG: darkMode ? "rgba(0,0,0,0.1)" : "rgba(255,255,255,0.6)",

    //primary colors
    primary1: darkMode ? "#39AF45" : "#39AF45",
    primary2: darkMode ? "#A5EFFF" : "#A5EFFF",
    primary3: darkMode ? "#4D8F67" : "#4D8F67",
    primary4: darkMode ? "#131438" : "#131438",
    primary5: darkMode ? "#13231F" : "#13231F",

    // color text
    primaryText1: darkMode ? "#5090EA" : "#57db48",

    // secondary colors
    secondary1: darkMode ? "#1F381D" : "#1F381D",
    secondary2: darkMode ? "#174541" : "#174541",
    secondary3: darkMode ? "#1F381D" : "#1F381D",

    // other
    red1: darkMode ? "#FF4343" : "#DA2D2B",
    red2: darkMode ? "#F82D3A" : "#DF1F38",
    red3: "#D60000",
    green1: darkMode ? "#27AE60" : "#007D35",
    yellow1: "#E3A507",
    yellow2: "#FF8F00",
    yellow3: "#F3B71E",
    blue1: darkMode ? "#324A5E" : "#316736",
    blue2: darkMode ? "#5199FF" : "#2172E5",
    error: darkMode ? "#FD4040" : "#DF1F38",
    success: darkMode ? "#27AE60" : "#007D35",
    warning: "#FF8F00",

    // dont wanna forget these blue yet
    blue4: darkMode ? "#153d6f70" : "#C4D9F8",
    // blue5: darkMode ? '#153d6f70' : '#EBF4FF',

    //----------------------------------------------
    //Colors Data Token
    //---------------------------------------------- 
    //primary colors
    primaryData1: darkMode ? "#5DFFFF" : "#5DFFFF",
    primaryData2: darkMode ? "#A5EFFF" : "#A5EFFF",
    primaryData3: darkMode ? "#4D8F67" : "#4D8F67",
    primaryData4: darkMode ? "#131438" : "#131438",
    primaryData5: darkMode ? "#060A2B" : "#060A2B",
    // text
    textPrimary1: darkMode ? "#000" : "#000",

  };
}

function theme(darkMode: boolean): DefaultTheme {
  return {
    ...colors(darkMode),

    grids: {
      sm: 8,
      md: 12,
      lg: 24,
    },

    //shadows
    shadow1: darkMode ? "#000" : "#2F80ED",

    // media queries
    mediaWidth: mediaWidthTemplates,

    // css snippets
    flexColumnNoWrap: css`
      display: flex;
      flex-flow: column nowrap;
    `,
    flexRowNoWrap: css`
      display: flex;
      flex-flow: row nowrap;
    `,
  };
}

export default function ThemeProvider({
  children,
}: {
  children: React.ReactNode;
}) {

  const darkMode = useIsDarkMode();

  const themeObject = useMemo(() => theme(darkMode), [darkMode]);

  return (
    <StyledComponentsThemeProvider theme={themeObject}>
      {children}
    </StyledComponentsThemeProvider>
  );
}

const TextWrapper = styled(Text) <{ color: keyof Colors }>`
  color: ${({ color, theme }) => (theme as any)[color]};
`;

/**
 * Preset styles of the Rebass Text component
 */

export const ThemedText = {
  Main(props: TextProps) {

    return <TextWrapper fontWeight={500} color={"text2"} {...props} />;
  },
  Link(props: TextProps) {
    return <TextWrapper fontWeight={500} color={"primary1"} {...props} />;
  },
  Label(props: TextProps) {
    return <TextWrapper fontWeight={600} color={"text1"} {...props} />;
  },
  Black(props: TextProps) {
    return <TextWrapper fontWeight={500} color={"text1"} {...props} />;
  },
  White(props: TextProps) {
    return <TextWrapper fontWeight={500} color={"white"} {...props} />;
  },
  Body(props: TextProps) {
    const currentLanguage = useActiveLocale();

    const size = currentLanguage === 'id-ID' ? 14 : 16
    return (
      <TextWrapper fontWeight={400} fontSize={size} color={"text1"} {...props} />
    );
  },
  TitlePage(props: TextProps) {
    const currentLanguage = useActiveLocale();

    const size = currentLanguage === 'id-ID' ? 27 : isMobile ? 27 : 60
    return (
      <TextWrapper fontWeight={900} fontSize={size} {...props} />
    );
  },
  Title(props: TextProps) {
    const currentLanguage = useActiveLocale();

    const size = currentLanguage === 'id-ID' ? isMobile ? 30 : 60 : isMobile ? 40 : 60
    return (
      <TextWrapper fontWeight={900} fontSize={size} {...props} />
    );
  },
  SubTitle(props: TextProps) {
    return (
      <TextWrapper
        fontWeight={500}
        fontSize={isMobile ? 18 : 20}
        fontStyle={"italic"}
        color={"text2"}
        {...props}
      />
    );
  },
  ExtraLargeHeader(props: TextProps) {
    return <TextWrapper fontWeight={600} fontSize={isMobile ? 25 : 40} {...props} />;
  },
  LargeHeader(props: TextProps) {
    return <TextWrapper fontWeight={600} fontSize={24} {...props} color={"primary1"} />;
  },
  MediumHeader(props: TextProps) {
    const currentLanguage = useActiveLocale();

    const size = currentLanguage === 'id-ID' ? isMobile ? 12 : 14 : 20
    return (
      <TextWrapper fontWeight={500} fontSize={size} color={"text1"} {...props} />
    );
  },
  SubHeader(props: TextProps) {
    return (
      <TextWrapper
        fontWeight={400}
        fontSize={isMobile ? 12 : 14}
        color={"text1"}
        {...props}
      />
    );
  },
  Small(props: TextProps) {
    return <TextWrapper fontWeight={500} fontSize={11} {...props} />;
  },
  Blue(props: TextProps) {
    return <TextWrapper fontWeight={500} color={"blue1"} {...props} />;
  },
  Yellow(props: TextProps) {
    return (
      <TextWrapper
        fontWeight={500}
        color={"yellow3"}
        {...props}
        fontSize={isMobile ? 12 : 14}
      />
    );
  },
  DarkGray(props: TextProps) {
    return <TextWrapper fontWeight={500} color={"text3"} {...props} />;
  },
  Gray(props: TextProps) {
    return <TextWrapper fontWeight={500} color={"bg3"} {...props} />;
  },
  Italic(props: TextProps) {
    return (
      <TextWrapper
        fontWeight={500}
        fontSize={12}
        fontStyle={"italic"}
        color={"text2"}
        {...props}
      />
    );
  },
  Error({ error, ...props }: { error: boolean } & TextProps) {
    return (
      <TextWrapper
        fontWeight={500}
        color={error ? "red1" : "text2"}
        fontSize={isMobile ? 12 : 14}
        {...props}
      />
    );
  },
  //Data Token
  TitleText(props: TextProps) {
    return <TextWrapper fontWeight={900} fontSize={isMobile ? 40 : 80} {...props} />;
  },
  TitleSection(props: TextProps) {
    return <TextWrapper fontWeight={900} fontSize={isMobile ? 35 : 60} {...props} />;
  },
  ExtraLargeText(props: TextProps) {
    return <TextWrapper fontWeight={500} fontSize={isMobile ? 30 : 50} {...props} />;
  },
  LargeText(props: TextProps) {
    return <TextWrapper fontWeight={900} fontSize={isMobile ? 20 : 24} {...props} />;
  },
  MediumText(props: TextProps) {
    return <TextWrapper fontSize={isMobile ? 16 : 20} {...props} />;
  },
  BodyText(props: TextProps) {
    return <TextWrapper fontSize={isMobile ? 12 : 20} {...props} />;
  },
  SmallText(props: TextProps) {
    return <TextWrapper fontSize={isMobile ? 14 : 20} {...props} />;
  },
  ExtraSmallText(props: TextProps) {
    return <TextWrapper fontSize={isMobile ? 11 : 20} {...props} />;
  },
};

export const ThemedGlobalStyle = createGlobalStyle`
html {
  color: ${({ theme }) => theme.text1};
  background-image: url(${process.env.PUBLIC_URL}/images/bg-data-token.svg) !important;
  background-size: cover;
  height: 100hv;
  font-family: Space Grotesk, sans-serif;
}

a {
 color: ${({ theme }) => theme.blue1}; 
}
`;
