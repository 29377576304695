import { Trans } from "@lingui/macro";
import styled from "styled-components/macro";
import { ThemedText } from "theme";

import Group from "assets/images/homeData/group.svg"
import { isMobile } from "utils/userAgent";
import { ButtonExternalLinkData } from "components/Link";

const Container = styled.div`
  width: 1100px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-top: 15rem;
  gap: 10rem;
  ${({ theme }) => theme.mediaWidth.upToMedium`
    flex-direction: column;
    gap: 2rem;
    margin-top: 8rem;
    width: 100%;
  `};

  ${({ theme }) => theme.mediaWidth.upToSmall`
    flex-direction: column;
    gap: 2rem;
    margin-top: 8rem;
    width: 100%;
  `};
`;

const Image = styled.img`
  width: 665px;
  height: 573px;
  ${({ theme }) => theme.mediaWidth.upToMedium`
    width: 100%;
    height: 331px;
  `};

  ${({ theme }) => theme.mediaWidth.upToSmall`
    width: 100%;
    height: 331px;
  `};
`;

const Column = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 2rem;
    ${({ theme }) => theme.mediaWidth.upToMedium`
      align-items: center;
    `};

    ${({ theme }) => theme.mediaWidth.upToSmall`
      align-items: center;
    `};
`;

export function Opportunities() {
  return (
    <Container id={"aboutData"}>
      <Column>
        <ThemedText.ExtraLargeText textAlign={isMobile ? "center" : "left"}>
          $DATA TOKEN - <Trans>UNLEASHING</Trans>
          <ThemedText.ExtraLargeText textAlign={isMobile ? "center" : "left"} color="primaryData1">
            <Trans>OPPORTUNITIES</Trans>
          </ThemedText.ExtraLargeText>
        </ThemedText.ExtraLargeText>
        {isMobile && <Image src={Group} alt="group-icon" />}
        <ThemedText.MediumText>
          <Trans>After a successful year with the GBC mining utility token, GBT introduces <b style={{ color: '#5DFFFF' }}>$DATA</b>, a groundbreaking token focusing on developing hosting infrastructures for DATA Centers. This marks a significant leap in our growth for 2024.</Trans>
        </ThemedText.MediumText>
        <ButtonExternalLinkData target="_blank"
          rel="noopener noreferrer"
          href="https://app.data-bsc.com/"
          width={isMobile ? "100%" : "256px"}
        >
          <ThemedText.LargeText>
            <Trans>Join</Trans>
          </ThemedText.LargeText>
        </ButtonExternalLinkData>
      </Column>
      {!isMobile && <Image src={Group} alt="group-icon" />}
    </Container>
  );
}
