import { Trans } from "@lingui/macro";
import styled from "styled-components/macro";
import { ThemedText } from "theme";

import NFT from "assets/images/homeData/nft.png"
import { isMobile } from "utils/userAgent";
import { ButtonExternalLinkData } from "components/Link";

const Container = styled.div`
  width: 1100px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-top: 15rem;
  gap: 10rem;
  ${({ theme }) => theme.mediaWidth.upToMedium`
    flex-direction: column;
    gap: 2rem;
    margin-top: 8rem;
    width: 100%;
  `};

  ${({ theme }) => theme.mediaWidth.upToSmall`
    flex-direction: column;
    gap: 2rem;
    margin-top: 8rem;
    width: 100%;
  `};
`;

const Image = styled.img`
  width: 665px;
  height: 573px;
  ${({ theme }) => theme.mediaWidth.upToMedium`
    width: 100%;
    height: 331px;
  `};

  ${({ theme }) => theme.mediaWidth.upToSmall`
    width: 100%;
    height: 331px;
  `};
`;

const Column = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 2rem;
    ${({ theme }) => theme.mediaWidth.upToMedium`
      align-items: center;
    `};

    ${({ theme }) => theme.mediaWidth.upToSmall`
      align-items: center;
    `};
`;

export function NftStake() {
  return (
    <Container id={"aboutData"}>
      <Column>
        <ThemedText.ExtraLargeText textAlign={isMobile ? "center" : "left"}>
          NFT <b style={{ color: '#5DFFFF' }}><Trans>Booster</Trans></b>
        </ThemedText.ExtraLargeText>
        {isMobile && <Image src={NFT} alt="nft-img" style={{ borderRadius: '16px', width: '100%', height: '100%' }} />}
        <ThemedText.MediumText>
          <Trans>Boost your rewards with our exclusive 200 units NFT booster. Access to white list presale and boost your profits</Trans>.
        </ThemedText.MediumText>
        <ButtonExternalLinkData target="_blank"
          rel="noopener noreferrer"
          href="https://app.data-bsc.com/"
          width={isMobile ? "100%" : "256px"}
        >
          <ThemedText.LargeText>
            <Trans>Join</Trans>
          </ThemedText.LargeText>
        </ButtonExternalLinkData>
      </Column>
      {!isMobile && <Image src={NFT} alt="nft-img" style={{ borderRadius: '24px', width: '300px', height: '375px' }} />}
    </Container>
  );
}
