import { Trans } from "@lingui/macro";
import styled from "styled-components/macro";
import { ThemedText } from "theme";
import { useLocation } from "react-router-dom";

const FooterFrame = styled.div`
  width: 100%;
  padding: 5rem;
  z-index: 21;
  background-image: ${({ theme }) =>
    `linear-gradient(to bottom, transparent 50%, ${theme.bg0} 50% )}}`};
  background-position: 0 -100%;
  background-size: 100% 200%;
  box-shadow: 0px 0px 0px 1px theme.bg2;
  transition: background-position 0.1s, box-shadow 0.1s;
  background-blend-mode: hard-light;
  ${({ theme }) => theme.mediaWidth.upToMedium`
    border-top-left-radius: 24px;
    border-top-right-radius: 24px;
    padding: 2rem;
  `};

  ${({ theme }) => theme.mediaWidth.upToSmall`
    border-top-left-radius: 24px;
    border-top-right-radius: 24px;
    padding: 2rem;
  `};
`;

/* const RowBetween = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  ${({ theme }) => theme.mediaWidth.upToMedium`
    justify-content: center;
  `};

  ${({ theme }) => theme.mediaWidth.upToSmall`
    justify-content: center;
    gap: 1rem;
  `};
`;

const Row = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 1rem;
  ${({ theme }) => theme.mediaWidth.upToMedium`
    justify-content: center;
  `};

  ${({ theme }) => theme.mediaWidth.upToSmall`
    justify-content: center;
    gap: 0.8rem;
  `};
`; */


const ResponsiveBox = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
  ${({ theme }) => theme.mediaWidth.upToMedium`
    flex-direction: column;
    gap: 2rem;
  `};

  ${({ theme }) => theme.mediaWidth.upToSmall`
    flex-direction: column;
  `};
`;

const ExternalLink = styled.a`
  color: ${({ theme }) => theme.text1};
  font-size: 16px;
  text-decoration: none;
  :hover {
    color: ${({ theme }) => theme.primaryData1};
  }
`;

export default function Footer() {
  const { pathname } = useLocation();

  return (
    <FooterFrame>
      <ResponsiveBox>
        <ThemedText.Body>
          © {new Date().getFullYear()} {pathname.startsWith("/") ? "Data" : "Data"}
        </ThemedText.Body>
        <ExternalLink
          href="https://data-bsc.com/documents/wp.pdf"
          target="_blank"
          rel="noopener noreferrer"
        >
          Whitepaper
        </ExternalLink>
        {/* <ExternalLink
          href="https://www.pinksale.finance/launchpad/0x509003c6C2E6F2A0E18d35a79e83fc53E239C0Ba?chain=ETH"
          target="_blank"
          rel="noopener noreferrer"
        >
          <Trans>Presale</Trans>
        </ExternalLink> */}
        <ExternalLink
          href="https://t.me/DataToken_Portal"
          target="_blank"
          rel="noopener noreferrer"
        >
          <Trans>Telegram</Trans>
        </ExternalLink>
        <ExternalLink
          href="https://twitter.com/data_host_bsc/status/1761115068601630757"
          target="_blank"
          rel="noopener noreferrer"
        >
          Twitter
        </ExternalLink>

      </ResponsiveBox>
    </FooterFrame>
  );
}
