import { Trans } from "@lingui/macro";
import styled from "styled-components/macro";
import { ThemedText } from "theme";

import BusinessIcon from "assets/images/homeData/business-plan.svg"
import StrategyIcon from "assets/images/homeData/strategy.svg"
import ConnectionsIcon from "assets/images/homeData/connections.svg"
import ConnectionsMobileIcon from "assets/images/homeData/connections-mobile.svg"
import OneIcon from "assets/images/homeData/one.svg"
import TwoIcon from "assets/images/homeData/two.svg"
import ThreeIcon from "assets/images/homeData/three.svg"
import { isMobile } from "utils/userAgent";
import { AutoColumn } from "components/Column";
import { ButtonExternalLinkData } from "components/Link";

const Container = styled.div`
  width: 1200px;
  margin-top: 15rem;
  ${({ theme }) => theme.mediaWidth.upToMedium`
    margin-top: 8rem;
    width: 100%;
  `};

  ${({ theme }) => theme.mediaWidth.upToSmall`
    margin-top: 8rem;
    width: 100%;
  `};
`;

const ResponsiveDiv = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  gap: 10rem;
  ${({ theme }) => theme.mediaWidth.upToMedium`
    flex-direction: column;
    gap: 2rem;
  `};

  ${({ theme }) => theme.mediaWidth.upToSmall`
    flex-direction: column;
    gap: 2rem;
  `};
`;

const Image = styled.img`
  width: 532px;
  height: 445px;
  ${({ theme }) => theme.mediaWidth.upToMedium`
    width: 100%;
    height: 326px;
  `};

  ${({ theme }) => theme.mediaWidth.upToSmall`
    width: 100%;
    height: 326px;
  `};
`;

const Row = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 1rem;
    ${({ theme }) => theme.mediaWidth.upToMedium`
        align-items: center;
    `};

    ${({ theme }) => theme.mediaWidth.upToSmall`
        align-items: center;
    `};
`;

const BusinessText = styled.div`
    display: flex;
    flex-direction: column;
    gap: 2rem;
    ${({ theme }) => theme.mediaWidth.upToMedium`
      align-items: center;
    `};

    ${({ theme }) => theme.mediaWidth.upToSmall`
      align-items: center;
    `};
`;

const StrategyText = styled.div`
    width: 700px;
    height: 270px;
    display: flex;
    flex-direction: column;
    gap: 2rem;
    ${({ theme }) => theme.mediaWidth.upToMedium`
      align-items: center;
      width: 100%;
      height: 100%;
    `};

    ${({ theme }) => theme.mediaWidth.upToSmall`
      align-items: center;
      width: 100%;
      height: 100%;
    `};
`;


const ResponsiveBox = styled.div`
  display: flex;
  flex-direction: column;
  gap: 2rem;
  ${({ theme }) => theme.mediaWidth.upToMedium`
    flex-direction: row;
    gap: 1rem;
  `};

  ${({ theme }) => theme.mediaWidth.upToSmall`
    flex-direction: row;
    gap: 1rem;
  `};
`;

const ResponsiveText = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  ${({ theme }) => theme.mediaWidth.upToMedium`
    flex-direction: column;
    gap: 2rem;
  `};

  ${({ theme }) => theme.mediaWidth.upToSmall`
    flex-direction: column;
    gap: 2rem;
  `};
`;


export function Business() {
  return (
    <Container id={"business"}>
      <ResponsiveDiv>
        <BusinessText>
          <ThemedText.TitleSection textAlign={isMobile ? "center" : "left"}>
            <Trans><b style={{ color: '#5DFFFF' }}>BUSINESS</b> PLAN</Trans>
          </ThemedText.TitleSection>
          {isMobile && <Image src={BusinessIcon} alt="business-icon" />}
          <ThemedText.MediumText>
            <Trans>The hosting and mining power rental sector is experiencing growing interest due to its profitability.</Trans>
          </ThemedText.MediumText>
          <ThemedText.MediumText>
            <Trans>However, this field faces several challenges, such as limitations in types and costs of equipment, complexity in the selection and maintenance of these, and difficulties in the location of the facilities.</Trans>
          </ThemedText.MediumText>
        </BusinessText>
        {!isMobile && <Image src={BusinessIcon} alt="business-icon" />}
      </ResponsiveDiv>
      <ThemedText.ExtraLargeText textAlign="center" my="8rem">
        <Trans><b style={{ color: '#5DFFFF' }}>Innovative Solutions </b> from Green Block Capital</Trans>
      </ThemedText.ExtraLargeText>
      <ResponsiveBox>
        {isMobile ? <img src={ConnectionsMobileIcon} alt="connections" /> : <img src={ConnectionsIcon} alt="connections" />}
        <ResponsiveText>
          <Row style={{ width: isMobile ? '100%' : '409px' }}>
            <img src={OneIcon} alt="one-icon" height="60px" />
            <ThemedText.SmallText>
              <Trans>The high initial investment required, often exceeding $300,000 for small facilities, as well as the requirement for complex contracts and licenses in other countries.</Trans>
            </ThemedText.SmallText>
          </Row>
          <Row style={{ width: isMobile ? '100%' : '343px' }}>
            <img src={TwoIcon} alt="two-icon" height="60px" />
            <ThemedText.SmallText>
              <Trans>Logistical and noise challenges associated with the installation of mining equipment.</Trans>
            </ThemedText.SmallText>
          </Row>
          <Row style={{ width: isMobile ? '100%' : '338px' }}>
            <img src={ThreeIcon} alt="three-icon" height="60px" />
            <ThemedText.SmallText>
              <Trans>The difficulty of using <b style={{ color: '#5DFFFF' }}>100% green energy</b> for the operation and maintenance of the equipment.</Trans>
            </ThemedText.SmallText>
          </Row>
        </ResponsiveText>
      </ResponsiveBox>
      <ResponsiveDiv style={{ marginTop: '8rem' }}>
        {!isMobile && <Image src={StrategyIcon} alt="strategy-icon" />}
        <StrategyText>
          <ThemedText.ExtraLargeText textAlign={isMobile ? "center" : "left"}>
            <Trans>Expansion <b style={{ color: '#5DFFFF' }}>Strategy</b></Trans>
          </ThemedText.ExtraLargeText>
          {isMobile && <Image src={StrategyIcon} alt="strategy-icon" />}
          <ThemedText.MediumText>
            <Trans>Our business plan is strategically designed for the acquisition and development of facilities to facilitate efficient and sustainable cryptocurrency mining.</Trans>
          </ThemedText.MediumText>
          <ThemedText.MediumText>
            <Trans>Green Block Capital's approach enhances access to the mining market for a broader investor spectrum while emphasizing sustainability and energy efficiency.</Trans>
          </ThemedText.MediumText>
        </StrategyText>
      </ResponsiveDiv>
      <AutoColumn gap="2rem" style={{ justifyItems: 'center' }}>
        <ThemedText.ExtraLargeText textAlign="center" mt="5rem">
          <Trans><b style={{ color: '#5DFFFF' }}>Summary</b></Trans>
        </ThemedText.ExtraLargeText>
        <ThemedText.MediumText textAlign="center">
          <Trans>Green Block Capital's business plan directly tackles current challenges in the cryptocurrency mining sector, offering innovative and accessible solutions for investors of all levels. Join us in shaping the future of crypto mining!</Trans>
        </ThemedText.MediumText>
        <ButtonExternalLinkData target="_blank"
          rel="noopener noreferrer"
          href="https://app.data-bsc.com/"
          width={isMobile ? "100%" : "256px"}
        >
          <ThemedText.LargeText>
            <Trans>Join</Trans>
          </ThemedText.LargeText>
        </ButtonExternalLinkData>
      </AutoColumn>
    </Container>
  );
}
