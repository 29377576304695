import { Trans } from "@lingui/macro";
import styled from "styled-components/macro";
import { ThemedText } from "theme";

import FeaturesIcon from "assets/images/homeData/features.svg"
import { isMobile } from "utils/userAgent";

const Container = styled.div`
  width: 1200px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-top: 15rem;
  gap: 10rem;
  ${({ theme }) => theme.mediaWidth.upToMedium`
    flex-direction: column;
    gap: 2rem;
    margin-top: 8rem;
    width: 100%;
  `};

  ${({ theme }) => theme.mediaWidth.upToSmall`
    flex-direction: column;
    gap: 2rem;
    margin-top: 8rem;
    width: 100%;
  `};
`;

const Image = styled.img`
  width: 482px;
  height: 447.34px;
  ${({ theme }) => theme.mediaWidth.upToMedium`
    width: 100%;
    height: 261.34px;
  `};

  ${({ theme }) => theme.mediaWidth.upToSmall`
    width: 100%;
    height: 261.34px;
  `};
`;

const Column = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 2rem;
    ${({ theme }) => theme.mediaWidth.upToMedium`
      align-items: center;
    `};

    ${({ theme }) => theme.mediaWidth.upToSmall`
      align-items: center;
    `};
`;

export function Features() {
  return (
    <Container id={"features"}>
      {!isMobile && <Image src={FeaturesIcon} alt="features-icon" />}
      <Column>
        <ThemedText.TitleSection textAlign={isMobile ? "center" : "left"}>
          <Trans>TECHNICAL</Trans>
          <ThemedText.TitleSection textAlign={isMobile ? "center" : "left"} color="primaryData1">
            <Trans>FEATURES</Trans>
          </ThemedText.TitleSection>
        </ThemedText.TitleSection>
        {isMobile && <Image src={FeaturesIcon} alt="features-icon" />}
        <ThemedText.MediumText>
          <Trans>As a ERC20 token, $DATA benefits from the security and efficiency of the Binance Smart Chain (BSC) network, known for its speed and low transaction costs.</Trans>
        </ThemedText.MediumText>
        <ThemedText.MediumText>
          <Trans>This technological choice underscores Green Block Capital's commitment to innovation and accessibility</Trans>.
        </ThemedText.MediumText>
      </Column>
    </Container>
  );
}
