import { Trans } from "@lingui/macro";
import styled from "styled-components/macro";
import { ThemedText } from "theme";

import BullEye from "assets/images/homeData/bulleye.svg"
import Up from "assets/images/homeData/up.png"
import Graphic from "assets/images/homeData/graphic.svg"
import { isMobile } from "utils/userAgent";
import { BlueDataCard, BorderCard } from "components/Card";
import { AutoColumn } from "components/Column";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleCheck } from "@fortawesome/free-solid-svg-icons";
import { ButtonExternalLinkData } from "components/Link";

const Container = styled.div`
  width: 1200px;
  display: flex;
  flex-direction: column;
  gap: 4rem;
  margin-top: 15rem;
  ${({ theme }) => theme.mediaWidth.upToMedium`
    margin-top: 8rem;
    width: 100%;
  `};

  ${({ theme }) => theme.mediaWidth.upToSmall`
    margin-top: 8rem;
    width: 100%;
  `};
`;

const ResponsiveCards = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  ${({ theme }) => theme.mediaWidth.upToMedium`
    flex-direction: column;
    gap: 2rem;
  `};

  ${({ theme }) => theme.mediaWidth.upToSmall`
    flex-direction: column;
    gap: 2rem;
  `};
`;

const ResponsiveBox = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  ${({ theme }) => theme.mediaWidth.upToMedium`
    flex-direction: column;
    gap: 2rem;
  `};

  ${({ theme }) => theme.mediaWidth.upToSmall`
    flex-direction: column;
    gap: 2rem;
  `};
`;

const Row = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: flex-start;
    gap: 1rem;
`;

const Column = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 2rem;
`;

const StyledCard = styled(BlueDataCard)`
    padding: 0;
    width: 370px;
    height: 462px;
    ${({ theme }) => theme.mediaWidth.upToMedium`
        width: 100%;
        height: 419px;
    `};

    ${({ theme }) => theme.mediaWidth.upToSmall`
        width: 100%;
        height: 419px;
    `};
`;

const Image = styled.img`
  width: 463px;
  height: 305.59px;
  ${({ theme }) => theme.mediaWidth.upToMedium`
    width: 100%;
    height: 236.98px;
  `};

  ${({ theme }) => theme.mediaWidth.upToSmall`
    width: 100%;
    height: 236.98px;
  `};
`;


export function Objectives() {
    return (
        <Container id={"objectivesData"}>
            <AutoColumn gap="2rem">
                <ThemedText.TitleSection textAlign="center">
                    $DATA TOKEN <Trans><b style={{ color: '#5DFFFF' }}>OBJECTIVES</b></Trans>
                </ThemedText.TitleSection>
                <ThemedText.MediumText textAlign="center">
                    <Trans>Investors, seize the chance to participate in DATA Center investments!</Trans>
                </ThemedText.MediumText>
            </AutoColumn>
            <ResponsiveCards>
                {isMobile &&
                    <StyledCard>
                        <BorderCard>
                            <Column>
                                <img src={Up} alt="up-icon" width={150} height={150} />
                                <ThemedText.MediumText>
                                    <Trans>Your investment will contribute to expanding GBT's capacity by building an additional <b style={{ color: '#5DFFFF' }}>3 MW</b>, aligning with our commitment to <b style={{ color: '#5DFFFF' }}>growth and success.</b></Trans>
                                </ThemedText.MediumText>
                            </Column>
                        </BorderCard>
                    </StyledCard>}

                <StyledCard>
                    <BorderCard>
                        <Column>
                            <img src={BullEye} alt="bulleye-icon" width={150} height={150} />
                            <ThemedText.MediumText>
                                <Trans>You will receive a share of the generated revenues through staking, estimated at <b style={{ color: '#5DFFFF' }}>80%</b> apy the first year.</Trans>
                            </ThemedText.MediumText>
                        </Column>
                    </BorderCard>
                </StyledCard>

                {!isMobile &&
                    <StyledCard>
                        <BorderCard>
                            <Column>
                                <img src={Up} alt="up-icon" width={150} height={150} />
                                <ThemedText.MediumText>
                                    <Trans>Your investment will contribute to expanding GBT's capacity by building an additional <b style={{ color: '#5DFFFF' }}>3 MW</b>, aligning with our commitment to <b style={{ color: '#5DFFFF' }}>growth and success.</b></Trans>
                                </ThemedText.MediumText>
                            </Column>
                        </BorderCard>
                    </StyledCard>}
            </ResponsiveCards>

            <ThemedText.ExtraLargeText textAlign={isMobile ? "center" : "left"} mt={isMobile ? "2rem" : "4rem"}>
                <Trans>Competitive Advantages</Trans>
            </ThemedText.ExtraLargeText>
            <ResponsiveBox>
                {isMobile && <Image src={Graphic} alt="graphic" />}
                <AutoColumn gap="2rem" style={{ width: isMobile ? '100%' : '603px' }}>
                    <Row>
                        <FontAwesomeIcon icon={faCircleCheck} style={{ fontSize: '40px' }} />
                        <ThemedText.MediumText width="fit-content">
                            <Trans><b style={{ color: '#5DFFFF' }}>Proven Track Record:</b> GBT has generated almost 2 BTC in profits with a 54% return on investment in the first year.</Trans>
                        </ThemedText.MediumText>
                    </Row>
                    <Row>
                        <FontAwesomeIcon icon={faCircleCheck} style={{ fontSize: '40px' }} />
                        <ThemedText.MediumText>
                            <Trans><b style={{ color: '#5DFFFF' }}>Buy Tax 0% | Sell Tax 5%:</b> A transparent fee structure benefiting the community.</Trans>
                        </ThemedText.MediumText>
                    </Row>
                    <Row>
                        <FontAwesomeIcon icon={faCircleCheck} style={{ fontSize: '40px' }} />
                        <ThemedText.MediumText>
                            <Trans><b style={{ color: '#5DFFFF' }}>75% Tokens for Community Holders:</b> We believe in empowering our community.</Trans>
                        </ThemedText.MediumText>
                    </Row>
                    <Row>
                        <FontAwesomeIcon icon={faCircleCheck} style={{ fontSize: '40px' }} />
                        <ThemedText.MediumText>
                            <Trans><b style={{ color: '#5DFFFF' }}>Innovative Tokenomics:</b> GBT token holders benefit from a unique distribution model.</Trans>
                        </ThemedText.MediumText>
                    </Row>
                </AutoColumn>
                {!isMobile && <Image src={Graphic} alt="graphic" />}
            </ResponsiveBox>
            <Column>
                <ButtonExternalLinkData target="_blank"
                    rel="noopener noreferrer"
                    href="https://app.data-bsc.com/"
                    width={isMobile ? "100%" : "256px"}
                >
                    <ThemedText.LargeText>
                        <Trans>Join</Trans>
                    </ThemedText.LargeText>
                </ButtonExternalLinkData>
            </Column>
        </Container>
    );
}
