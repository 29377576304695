import { Trans } from "@lingui/macro";
import { AutoColumn } from "components/Column";
import styled from "styled-components/macro";
import { ThemedText } from "theme";

import DataMan from "assets/images/homeData/dataman.svg"
import { isMobile } from "utils/userAgent";
import { ButtonExternalLinkData } from "components/Link";

const Container = styled.div`
  width: 1200px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-top: 8rem;
  ${({ theme }) => theme.mediaWidth.upToMedium`
    flex-direction: column;
    gap: 2rem;
    width: 100%;
  `};

  ${({ theme }) => theme.mediaWidth.upToSmall`
    flex-direction: column;
    gap: 2rem;
    width: 100%;
  `};
`;

const Image = styled.img`
  width: 331px;
  height: 421px;
  ${({ theme }) => theme.mediaWidth.upToMedium`
    width: 242px;
    height: 308px;
  `};

  ${({ theme }) => theme.mediaWidth.upToSmall`
    width: 242px;
    height: 308px;
  `};
`;

const Column = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 2rem;
    ${({ theme }) => theme.mediaWidth.upToMedium`
      align-items: center;
    `};

    ${({ theme }) => theme.mediaWidth.upToSmall`
      align-items: center;
    `};
`;

export function Header() {
  return (
    <Container>
      <Column>
        <ThemedText.TitleText textAlign={isMobile ? "center" : "left"}>
          <Trans>NEW</Trans>
          <ThemedText.TitleText textAlign={isMobile ? "center" : "left"}>
            DATA <span style={{ color: '#5DFFFF' }}>TOKEN</span>
          </ThemedText.TitleText>
        </ThemedText.TitleText>
        {isMobile && <Image src={DataMan} alt="dataman" />}
        <AutoColumn>
          <ThemedText.MediumText textAlign={isMobile ? "center" : "left"}>
            <b>DTA Contract:</b>
          </ThemedText.MediumText>
          <ThemedText.SmallText textAlign="left">
          0x8214C9F780cC2b19BAe84703C5281602A1aBfa25
          </ThemedText.SmallText>
        </AutoColumn>
        <AutoColumn>
          <ThemedText.MediumText textAlign={isMobile ? "center" : "left"}>
            <Trans><b>Unlock the Future:</b> Click Here to Join the Crypto</Trans>
          </ThemedText.MediumText>
          <ThemedText.MediumText textAlign={isMobile ? "center" : "left"}>
            <Trans>Revolution with Green Block Capital!</Trans>
          </ThemedText.MediumText>
        </AutoColumn>
        <ButtonExternalLinkData target="_blank"
          rel="noopener noreferrer"
          href="https://app.data-bsc.com/"
          width={isMobile ? "100%" : "256px"}
        >
          <ThemedText.LargeText>
            <Trans>Join</Trans>
          </ThemedText.LargeText>
        </ButtonExternalLinkData>
      </Column>
      {!isMobile && <Image src={DataMan} alt="dataman" />}
    </Container>
  );
}
