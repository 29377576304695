import { Trans } from "@lingui/macro";
import styled from "styled-components/macro";
import { ThemedText } from "theme";

import DataToken from "assets/images/homeData/data-token.png"
import Miner from "assets/images/homeData/miner.png"
import { BlueDataCard, BorderCard } from "components/Card";
import { AutoColumn } from "components/Column";

const Container = styled.div`
  width: 1200px;
  display: flex;
  flex-direction: column;
  gap: 4rem;
  margin-top: 15rem;
  ${({ theme }) => theme.mediaWidth.upToMedium`
    margin-top: 8rem;
    width: 100%;
  `};

  ${({ theme }) => theme.mediaWidth.upToSmall`
    margin-top: 8rem;
    width: 100%;
  `};
`;

const ResponsiveCards = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  ${({ theme }) => theme.mediaWidth.upToMedium`
    flex-direction: column;
    gap: 2rem;
  `};

  ${({ theme }) => theme.mediaWidth.upToSmall`
    flex-direction: column;
    gap: 2rem;
  `};
`;



const Column = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 2rem;
`;

const StyledCard = styled(BlueDataCard)`
    padding: 0;
    width: 370px;
    height: 462px;
    ${({ theme }) => theme.mediaWidth.upToMedium`
        width: 100%;
        height: 419px;
    `};

    ${({ theme }) => theme.mediaWidth.upToSmall`
        width: 100%;
        height: 419px;
    `};
`;



export function Profits() {
    return (
        <Container id={"objectivesData"}>
            <AutoColumn gap="2rem">
                <ThemedText.TitleSection textAlign="center">
                    <Trans>Innovative <b style={{ color: '#5DFFFF' }}>Profit</b> Distribution</Trans>
                </ThemedText.TitleSection>
                <ThemedText.MediumText textAlign="center">
                    <Trans>Now, with a renewed focus on maximizing returns for our investors, we have updated our profit distribution strategy for DATA token holders</Trans>.
                </ThemedText.MediumText>
            </AutoColumn>
            <ResponsiveCards>
                <StyledCard>
                    <BorderCard>
                        <Column>
                            <img src={DataToken} alt="bulleye-icon" width={150} height={150} />
                            <ThemedText.MediumText>
                                <Trans>Of the generated income, <b style={{ color: '#5DFFFF' }}>50%</b> will be distributed monthly to the wallets of DATA token stakers</Trans>.
                            </ThemedText.MediumText>
                        </Column>
                    </BorderCard>
                </StyledCard>

                <StyledCard>
                    <BorderCard>
                        <Column>
                            <img src={Miner} alt="up-icon" width={150} height={150} />
                            <ThemedText.MediumText>
                                <Trans>The other <b style={{ color: '#5DFFFF' }}>50%</b> will be strategically used to acquire cutting-edge mining equipment, specifically the IbeLink BM-KSMax (10500G) for mining the KASPA currency</Trans>.
                            </ThemedText.MediumText>
                        </Column>
                    </BorderCard>
                </StyledCard>
            </ResponsiveCards>
            <ThemedText.MediumText textAlign="center">
                <Trans>The profits generated by these machines will be distributed among DATA holders, ensuring a steady flow of investment return and reaffirming our commitment to innovation and sustained growth</Trans>.
            </ThemedText.MediumText>
        </Container>
    );
}
