import styled from "styled-components/macro";
import { AutoColumn } from "../../components/Column";
import { Header } from "./components/Header";
import { AboutUs } from "./components/AboutUs";
import { Opportunities } from "./components/Opportunities";
import { Objectives } from "./components/Objectives";
import { Features } from "./components/Features";
import { AppData } from "./components/AppData";
import { Roadmap } from "./components/Roadmap";
import { Values } from "./components/Values";
import { Business } from "./components/Business";
import { Profits } from "./components/Profits";
import { NftStake } from "./components/NftStake";

const PageWrapper = styled(AutoColumn)`
  max-width: 1200px;
  width: 100%;

  ${({ theme }) => theme.mediaWidth.upToMedium`
    max-width: 800px;
  `};

  ${({ theme }) => theme.mediaWidth.upToSmall`
    max-width: 500px;
  `};
`;


export function HomeData() {
  return (
    <PageWrapper>
      <Header />
      <AboutUs />
      <Opportunities />
      <Objectives />
      <Profits />
      <NftStake />
      <Features />
      <AppData />
      <Roadmap />
      <Values />
      <Business />
    </PageWrapper>
  );
}
