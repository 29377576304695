
import styled from "styled-components/macro";

import RoadMap from "assets/images/homeData/roadmap.png"
import RoadMapMobile from "assets/images/homeData/roadmap-mobile.png"
import { ThemedText } from "theme";
import { Trans } from "@lingui/macro";
import { isMobile } from "utils/userAgent";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 5rem;
  margin-top: 15rem;
  ${({ theme }) => theme.mediaWidth.upToMedium`
    margin-top: 8rem;
    gap: 2rem;
  `};

  ${({ theme }) => theme.mediaWidth.upToSmall`
    margin-top: 8rem;
    gap: 2rem;
  `};
`;

const Image = styled.img`
  width: 1311px;
  height: 365px;
  ${({ theme }) => theme.mediaWidth.upToMedium`
    width: 100%;
    height: 100%;
  `};

  ${({ theme }) => theme.mediaWidth.upToSmall`
    width: 100%;
    height: 100%;
  `};
`;


export function Roadmap() {
  return (
    <Container id={"roadmap"}>
      <ThemedText.TitleSection textAlign="center">
        <Trans>OUR <b style={{ color: '#5DFFFF' }}>ROADMAP</b></Trans>
      </ThemedText.TitleSection>
      <Image src={isMobile ? RoadMapMobile : RoadMap} alt="roadmap" />
    </Container>
  );
}
